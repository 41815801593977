<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="secondary rounded-lg mt-8" style="height: 24px; width: 8px" />

      <v-card class="py-6 px-8 mb-4" color="surface" width="100%" flat tile>
        <div class="text-h5 font-weight-bold mb-6">
          <span class="saira"> Dados Pessoais </span>
        </div>

        <v-form v-model="valid" ref="form" autocomplete="off" class="mb-6">
          <div @keypress.enter.prevent="submit()" class="mb-4">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-md-2">
                <div class="label-input">Nome</div>
                <v-text-field
                  v-model="form.firstName"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Seu nome"
                  :rules="rules"
                  solo
                  flat
                  readonly
                  @keydown.space.prevent
                />
              </v-col>

              <v-col cols="12" md="6" class="pl-md-2">
                <div class="label-input">Sobrenome</div>
                <v-text-field
                  v-model="form.lastName"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Seu nome"
                  :rules="rules"
                  solo
                  flat
                  readonly
                  @keydown.space.prevent
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-md-2">
                <div class="label-input">E-mail</div>
                <v-text-field
                  v-model="form.email"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Seu e-mail"
                  :rules="rules"
                  solo
                  flat
                  readonly
                  @keydown.space.prevent
                />
              </v-col>

              <v-col cols="12" md="6" class="pl-md-2">
                <div class="label-input">Telefone</div>
                <v-text-field
                  v-model="form.phone"
                  v-mask="'(##) #####-####'"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Seu telefone"
                  :rules="rules"
                  readonly
                  solo
                  flat
                />
              </v-col>
            </v-row>

            <div class="label-input">Nickname</div>
            <v-text-field
              v-model="form.nickname"
              class="rounded-lg"
              background-color="accent"
              placeholder="Seu nickname"
              :rules="rules"
              solo
              flat
              @keydown.space.prevent
            />

            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-md-2">
                <div class="label-input">Gênero</div>
                <v-select
                  v-model="form.genre"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Selecione seu gênero"
                  item-text="label"
                  item-value="value"
                  :items="genders"
                  :rules="rules"
                  solo
                  flat
                />
              </v-col>

              <v-col cols="12" md="6" class="pl-md-2">
                <div class="label-input">Data de nascimento</div>
                <v-text-field
                  v-model="form.birthDate"
                  v-mask="'## / ## / ####'"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="dia / mês / ano"
                  :rules="rules"
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card>
    </div>

    <div class="d-flex justify-end">
      <v-btn
        class="rounded-r-0 rounded-l-lg text-none mr-6"
        color="surface"
        width="96"
        large
        @click="startForm()"
      >
        Cancelar
      </v-btn>

      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        width="96"
        large
        @click="submit()"
      >
        Salvar
      </v-btn>
    </div>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { updateUserData } from "@/services/user.js";
import { displayAlert } from "@/utils";
import { mask } from "vue-the-mask";
const moment = require("moment");

export default {
  data() {
    return {
      loading: false,
      valid: false,
      genders: [
        {
          label: "Feminino",
          value: "F",
        },
        {
          label: "Masculino",
          value: "M",
        },
        {
          label: "Outro",
          value: "O",
        },
      ],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        nickname: "",
        genre: "",
        birthDate: "",
      },
    };
  },

  directives: { mask },

  beforeMount() {
    this.startForm();
  },

  computed: {
    ...mapState(["user", "localUser"]),

    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          nickname: this.form.nickname,
          genre: this.form.genre,
          birthDate: moment(this.form.birthDate, "DD / MM / YYYY").format(
            "YYYY-MM-DD"
          ),
        };

        await updateUserData(payload).then((res) => {
          this.displayAlert(res.message);
          this.$root.$emit("refresh-user");
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    startForm() {
      this.form.firstName = this.user.firstName;
      this.form.lastName = this.user.lastName;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone;
      this.form.nickname = this.localUser.nickname;
      this.form.genre = this.localUser.genre;
      this.form.birthDate = moment(this.localUser.birthDate).format(
        "DD / MM / YYYY"
      );
    },

    displayAlert,
  },
};
</script>

<style></style>
